@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --primary-color: #d15424;
    --secondary-color: #294a93;
    --black-color: #001931;
    --gap: 0rem;
    --duration: 30s;
    --marquee-start: 0;
    --marquee-end: calc(-100% - var(--gap));
  }
}
.loader {
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes marquee {
  from {
    transform: translateX(var(--marquee-start));
  }
  100% {
    transform: translate(var(--marquee-end));
  }
}

.marquee-group {
  flex-shrink: 0;
  gap: var(--gap);
  animation: marquee var(--duration) linear infinite;
}

.marquee {
  gap: var(--gap);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

body {
  /* max-width: 1440px; */
  width: 100%;
  margin: 0 auto;
}

/* Vimeo Player */
.vp-center {
  display: block !important;
}

ol {
  list-style-type: decimal;
  margin-left: 1rem;
}

ul {
  list-style-type: disc;
  margin-left: 1rem;
}

li {
  margin-bottom: 12px;
}

.afterhero > div {
  width: calc(20% - 10px);
}

.sticky-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Adjust the z-index as needed */
  /* Add any other styling you want for the sticky navbar */
}

@media screen and (max-width: 1024px) {
  .afterhero > div {
    width: calc(50% - 20px);
  }
}

@media screen and (max-width: 380px) {
  .afterhero > div {
    width: calc(100% - 30px);
  }
}
