.custom-calendar {
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
  width: 0px;
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: #ffcb28;
  border-radius: 20px;
  width: 0px;
}
.react-calendar {
  width: 100%;
  height: 100%;
  border: none;
  background: #f1f1f1;
}
.bouncing-image2 {
  position: relative;
  height: 65vh;
  object-fit: contain;
  opacity: 0;
  animation: fadeIn 2s forwards;
}
.innersection {
  max-width: 1200px;
  margin: auto;
}
.paper {
  height: 100%;
  padding: 30px 15px;
  border-radius: 20px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease,
    background-color 0.3s ease;
  transform: translateY(0);
}

.paper:hover {
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.3);
  transform: translateY(-10px) scale(1.05) rotate(1deg);
  background-color: #f9f9f9; /* Optional background color change */
}

.paper img {
  transition: transform 0.3s ease;
}

.paper:hover img {
  transform: scale(1.1); /* Scale up image on hover */
}

@media (max-width: 600px) {
  .innersection {
    padding: 0 15px;
  }
}
@media (max-width: 1200px) {
  .innersection {
    max-width: 900px;
    margin: auto;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes sinusoidal-wave {
  0% {
    transform: translateY(0);
  }
  12.5% {
    transform: translateY(-10px);
  }
  25% {
    transform: translateY(0);
  }
  37.5% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0);
  }
  62.5% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(0);
  }
  87.5% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.sinusoidal-wave-animation {
  animation: sinusoidal-wave 2s ease-in-out infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bouncing-image {
  animation: bounce 2s infinite;
}
.react-calendar__tile {
  color: black; /* Default date color */
}
.GoogleLogin {
  width: 100% !important;
  position: absolute;
}

/* Today's date */
.react-calendar__tile--now {
  background: #d15424; /* Background color for today's date */
  color: #fff; /* Text color for today's date */
  border-radius: 55px;
}

/* Selected date */
.react-calendar__tile--active {
  background: #d15424; /* Background color for selected date */
  color: white; /* Text color for selected date */
}

/* Hovered date */
.react-calendar__tile:enabled:hover {
  background: #d15424; /* Background color for hovered date */
  color: #000; /* Text color for hovered date */
}

/* Custom date content */
.custom-date {
  font-weight: bold;
  color: #d15424; /* Change this to your desired color */
}
@keyframes zoomIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoomOut {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}
/* Background animation */
.landing-page__background {
  min-height: 60vh;
  background: url("https://picsum.photos/200/300") center/cover no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: backgroundMotion 12s infinite ease-in-out;
}

.landing-page__overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

/* Wrapper for text and button */
.landing-page__content {
  width: 65%;
  z-index: 1000;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

/* Heading animation */
.landing-page__heading {
  font-size: 48px;
  font-weight: 600;
  letter-spacing: -0.5px;
  text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  white-space: nowrap;
  border-right: 3px solid #d15242;
  display: inline-block;
  animation: typewriter 4s steps(30) 1, colorPulse 4s infinite;
}

/* Button styles */
.landing-page__button {
  margin-top: 1.75rem;
  background: #d15242;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 0.375rem;
  text-transform: uppercase;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s, color 0.3s;
  cursor: pointer;
}

.landing-page__button:hover {
  background: white;
  color: black;
}

/* Keyframes for animations */
@keyframes backgroundMotion {
  0% {
    transform: scale(1.1) translateX(0);
  }
  50% {
    transform: scale(1.2) translateX(-10px);
  }
  100% {
    transform: scale(1.1) translateX(0);
  }
}

@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes colorPulse {
  0%,
  100% {
    color: white;
  }
  50% {
    color: #aaa;
  }
}
